import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Signin from "./Pages/auth/Signin";
import Dashboard from "./Pages/dashboard/Dashboard";
import Members from "./Pages/settings/members/Members";
import Users from "./Pages/settings/users/Users";
import Commodity from "./Pages/settings/commodity/Commodity";
import Chatbot from "./Pages/settings/chatbot/Chatbot";
import Tutorials from "./Pages/marketing/tutorials/Tutorials";
import Community from "./Pages/marketing/community/Community";
import FAQs from "./Pages/marketing/faqs/Faqs";
import Notification from "./Pages/marketing/notifications/Notification";
import Feedbacks from "./Pages/marketing/feedbacks/FeedBack";
import Plant from "./Pages/plants/Plant";
import AddPlant from "./Pages/plants/AddPlant";
import Device from "./Pages/device/Device";
import DeviceLogs from "./Pages/deviceLogs/DeviceLogs";
import ServiceRequest from "./Pages/serviceRequest/ServiceRequest";
import AddTutorial from "./Pages/marketing/tutorials/addTutorials/AddTutorials";
import AddFaqs from "./Pages/marketing/faqs/AddFaqs";
import AddNotification from "./Pages/marketing/notifications/AddNotification";
import AddCommunity from "./Pages/marketing/community/AddCommunity";
import FeedBackDetails from "./Pages/marketing/feedbacks/FeedbackDetails";
import EditTutorial from "./Pages/marketing/tutorials/editTutorial/EditTutorial";
import EditFaqs from "./Pages/marketing/faqs/EditFaqs";
import EditNotification from "./Pages/marketing/notifications/EditNotification";
import EditCommunity from "./Pages/marketing/community/EditCommunity";
import AddCommodity from "./Pages/settings/commodity/AddCommidty";
import EditCommodity from "./Pages/settings/commodity/EditCommidity";
import OtherDevices from "./Pages/settings/other_devices/OtherDevices";
import Firmware from "./Pages/settings/firmware/Firmware";
import DetailedServiceRequest from "./Pages/serviceRequest/DetailedServiceRequest";
import AddMember from "./Pages/settings/members/AddMember";
import AddUser from "./Pages/settings/users/AddUser";
import AddDevice from "./Pages/device/AddDevice";
import EditDevice from "./Pages/device/EditDevice";
import EditPlant from "./Pages/plants/EditPlant";
import ViewPlant from "./Pages/plants/ViewPlant";
import ManualReport from "./Pages/report/manualReport/ManualReport";
import PerformanceReport from "./Pages/report/PerformanceReport";
import Enquiry from "./Pages/enquiry/Enquiry";
import AddFirmware from "./Pages/settings/firmware/AddFirmware";
import EditFirmware from "./Pages/settings/firmware/EditFirmware";
import AddOtherDevice from "./Pages/settings/other_devices/AddOtherDevice";
import EditOtherDevice from "./Pages/settings/other_devices/EditOtherDevice";
import AddManualReport from "./Pages/report/manualReport/AddManualReport";
import DetailedManualReport from "./Pages/report/manualReport/DetailedManualReport";
import DeviceLogReport from "./Pages/deviceLogs/device_log_report";
import DetailedDevice from "./Pages/device/DetailedDevice";
import EditUser from "./Pages/settings/users/EditUser";
import DashboardDown from "./Pages/DashboardDown";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route
          exact
          path="/signup"
          element={
            <Layout noSidebar>
              <Signup />
            </Layout>
          }
        /> */}
        <Route
          exact
          path="/"
          element={
            <Layout noSidebar>
              <Signin />
            </Layout>
          }
        />
        {/* <Route
          exact
          path="/forgotPassword"
          element={
            <Layout noSidebar>
              <ForgotPassword />
            </Layout>
          }
        /> */}
        {/* <Route
          exact
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        /> */}
        <Route
          exact
          path="/dashboard"
          element={
            <Layout noSidebar={true}>
              <DashboardDown />
            </Layout>
          }
        />
        {/* <Route
          exact
          path="/plant"
          element={
            <Layout>
              <Plant />
            </Layout>
          }
        />
        <Route
          exact
          path="/plant/add"
          element={
            <Layout>
              <AddPlant />
            </Layout>
          }
        />
        <Route
          exact
          path="/plant/edit"
          element={
            <Layout>
              <EditPlant />
            </Layout>
          }
        />
        <Route
          exact
          path="/users/edit"
          element={
            <Layout>
              <EditUser />
            </Layout>
          }
        />
        <Route
          exact
          path="/plant/view"
          element={
            <Layout>
              <ViewPlant />
            </Layout>
          }
        />
        <Route
          exact
          path="/device"
          element={
            <Layout>
              <Device />
            </Layout>
          }
        />
        <Route
          exact
          path="/device/add"
          element={
            <Layout>
              <AddDevice />
            </Layout>
          }
        />
        <Route
          exact
          path="/device/edit"
          element={
            <Layout>
              <EditDevice />
            </Layout>
          }
        />
        <Route
          exact
          path="/device/:id"
          element={
            <Layout>
              <DetailedDevice />
            </Layout>
          }
        />
        <Route
          exact
          path="/device-logs"
          element={
            <Layout>
              <DeviceLogs />
            </Layout>
          }
        />
        <Route
          exact
          path="/device-logs/report/:id"
          element={
            <Layout>
              <DeviceLogReport />
            </Layout>
          }
        />
        <Route
          exact
          path="/service-request"
          element={
            <Layout>
              <ServiceRequest />
            </Layout>
          }
        />
        <Route
          exact
          path="/enquiries"
          element={
            <Layout>
              <Enquiry />
            </Layout>
          }
        />
        <Route
          exact
          path="/manual"
          element={
            <Layout>
              <ManualReport />
            </Layout>
          }
        />
        <Route
          exact
          path="/manual/add"
          element={
            <Layout>
              <AddManualReport />
            </Layout>
          }
        />
        <Route
          exact
          path="/manual/:id"
          element={
            <Layout>
              <DetailedManualReport />
            </Layout>
          }
        />
        <Route
          exact
          path="/performance"
          element={
            <Layout>
              <PerformanceReport />
            </Layout>
          }
        />

        <Route
          exact
          path="/members"
          element={
            <Layout>
              <Members />
            </Layout>
          }
        />
        <Route
          exact
          path="/members/add"
          element={
            <Layout>
              <AddMember />
            </Layout>
          }
        />
        <Route
          exact
          path="/users"
          element={
            <Layout>
              <Users />
            </Layout>
          }
        />
        <Route
          exact
          path="/users/add"
          element={
            <Layout>
              <AddUser />
            </Layout>
          }
        />
        <Route
          exact
          path="/commodity"
          element={
            <Layout>
              <Commodity />
            </Layout>
          }
        />
        <Route
          exact
          path="/commodity/add-commodity"
          element={
            <Layout>
              <AddCommodity />
            </Layout>
          }
        />
        <Route
          exact
          path="/commodity/edit-commodity"
          element={
            <Layout>
              <EditCommodity />
            </Layout>
          }
        />

        <Route
          exact
          path="/other-devices"
          element={
            <Layout>
              <OtherDevices />
            </Layout>
          }
        />
        <Route
          exact
          path="/other-devices/add"
          element={
            <Layout>
              <AddOtherDevice />
            </Layout>
          }
        />
        <Route
          exact
          path="/other-devices/edit"
          element={
            <Layout>
              <EditOtherDevice />
            </Layout>
          }
        />

        <Route
          exact
          path="/firmware"
          element={
            <Layout>
              <Firmware />
            </Layout>
          }
        />
        <Route
          exact
          path="/firmware/add"
          element={
            <Layout>
              <AddFirmware />
            </Layout>
          }
        />
        <Route
          exact
          path="/firmware/edit"
          element={
            <Layout>
              <EditFirmware />
            </Layout>
          }
        />

        <Route
          exact
          path="/chatbot"
          element={
            <Layout>
              <Chatbot />
            </Layout>
          }
        />
        <Route
          exact
          path="/tutorials"
          element={
            <Layout>
              <Tutorials />
            </Layout>
          }
        />
        <Route
          exact
          path="/tutorials/add-tutorial"
          element={
            <Layout>
              <AddTutorial />
            </Layout>
          }
        />
        <Route
          exact
          path="/tutorials/edit-tutorial"
          element={
            <Layout>
              <EditTutorial />
            </Layout>
          }
        />
        <Route
          exact
          path="/faqs"
          element={
            <Layout>
              <FAQs />
            </Layout>
          }
        />
        <Route
          exact
          path="/faqs/add-faqs"
          element={
            <Layout>
              <AddFaqs />
            </Layout>
          }
        />
        <Route
          exact
          path="/faqs/edit-faqs"
          element={
            <Layout>
              <EditFaqs />
            </Layout>
          }
        />
        <Route
          exact
          path="/notification"
          element={
            <Layout>
              <Notification />
            </Layout>
          }
        />
        <Route
          exact
          path="/notification/add-notification"
          element={
            <Layout>
              <AddNotification />
            </Layout>
          }
        />
        <Route
          exact
          path="/notification/edit-notification"
          element={
            <Layout>
              <EditNotification />
            </Layout>
          }
        />
        <Route
          exact
          path="/community"
          element={
            <Layout>
              <Community />
            </Layout>
          }
        />
        <Route
          exact
          path="/community/add-community"
          element={
            <Layout>
              <AddCommunity />
            </Layout>
          }
        />
        <Route
          exact
          path="/community/edit-community"
          element={
            <Layout>
              <EditCommunity />
            </Layout>
          }
        />
        <Route
          exact
          path="/feedbacks"
          element={
            <Layout>
              <Feedbacks />
            </Layout>
          }
        />
        <Route
          exact
          path="/feedbacks/:id"
          element={
            <Layout>
              <FeedBackDetails />
            </Layout>
          }
        />
        <Route
          exact
          path="/service-request/:id"
          element={
            <Layout>
              <DetailedServiceRequest />
            </Layout>
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
